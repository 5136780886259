import React from 'react';
import ZapierIcon from "../../../assets/icons/zapier.svg";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";

const Zapier = () => {
    return (
        <Col md="6" xxl="3" xl="4">
            <Link
            to="/integrations/meet"
            className="int-col"
            >
            <div className="img">
                <img src={ZapierIcon} alt="Zapier" />
            </div>
            <div className="text">
                <h3>Zapier</h3>
                <p>
                Lorem Ipsum is simply dummy text of the
                printing an typesetting industry.
                </p>
            </div>
            </Link>
        </Col>
    );
}

export default Zapier;