import React, { useState, useEffect } from "react";
import logoLg from "../../assets/images/lg-logo.png";
import crossBtn from "../../assets/images/clear.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ErrorMessage, Formik, Form } from "formik";
import axios from "../../axios-instance";
import * as Yup from "yup";
import visibility from "../../assets/icons/visibility.svg";
import visibilityOff from "../../assets/icons/visibility_off.svg";
import isEmpty from "../../validation/is-empty";
import Loader from "../../components/Loader/Loader";
import { notification } from "../../utils/utility";
import setAuthToken from "../../utils/setAuthToken";
import { encryptUserRole } from "../../utils/authHelper";
import { SET_CURRENT_USER } from "../../actions/types";
import FormField from "../../components/FormField/FormField";
import { getTimezones } from "../../actions/companyActions";
import { connect } from "react-redux";

const validation = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid Email").required("Email is required"),
  password: Yup.string().required("Password is required"),
  confirm_password: Yup.string().required("Confirm Password is required"),
  timezone_id: Yup.string().required("Timezone is required."),
});

let initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  confirm_password: "",
  timezone_id: "",
};

const Signup = (props) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState(false);

  const [formValues, setFormValues] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [timezones, setTimezones] = useState([]);

  const navigate = useNavigate();
  const { token } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    const { getTimezones } = props || {};
    if (isEmpty(token)) {
      navigate("/admin/login");
    }

    setPageLoading(true);

    axios
      .post("/verify-staff-invite-token", {
        token,
      })
      .then((response) => {
        if (response.status === 200) {
          const { data: resData } = response;
          const { data } = resData;
          setFormValues({
            id: data.id,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            password: "",
            confirm_password: "",
            timezone_id: data.timezone_id ? data.timezone_id : "",
          });
          setPageLoading(false);
        } else {
          notification.error("Invalid token redirecting to login page");
          setTimeout(() => {
            navigate("/admin/login");
          }, 3000);
        }
      })
      .catch((error) => {
        notification.error("Invalid token redirecting to login page");
        setTimeout(() => {
          navigate("/admin/login");
        }, 3000);
      });

    getTimezones({
      data: {},
      onSuccess: function (data) {
        setPageLoading(false);
        setTimezones(data.timezones);
      },
      onError: function (error) {
        console.log("error", error);
      },
    });
  }, []);

  const changePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const changeConfirmPasswordVisibility = () => {
    setConfirmPasswordVisibility(!confirmPasswordVisibility);
  };
  return (
    <>
      <Loader isShowLoader={pageLoading} />
      <Formik
        validationSchema={validation}
        initialValues={formValues || initialValues}
        enableReinitialize
        onSubmit={(values, formikHelpers) => {
          setPageLoading(true);
          axios
            .post("/register-staff", values)
            .then((response) => {
              const { access_token, jwt_custom_claims } = response.data.data;
              localStorage.setItem("config", JSON.stringify(jwt_custom_claims));
              setAuthToken(access_token);
              localStorage.setItem("userType", encryptUserRole("staff"));
              localStorage.setItem("jwtToken", access_token);

              dispatch({
                type: SET_CURRENT_USER,
              });

              setPageLoading(false);
              navigate("/staff/signup-complete");
              notification.success("User Signup Successfully");
            })
            .catch((error) => {
              setPageLoading(false);
              if (error.response.data.code === 254) {
                formikHelpers.setFieldError(
                  "email",
                  error.response.data.message
                );
              }
            });
        }}
      >
        {(formik) => {
          const { values, handleChange, handleSubmit, handleBlur } = formik;
          console.log("values", values);
          return (
            <div className="signup-process">
              <div className="logo-side">
                <img src={logoLg} />
              </div>
              <div className="signup">
                <div className="cross-btn">
                  <Link to="landingPage">
                    <img width="33" src={crossBtn} />
                  </Link>
                </div>
                <div className="signup-form">
                  <form onSubmit={handleSubmit}>
                    <h1>Get Started!</h1>
                    <p className="r-p">
                      You Are One Password Away From Creating Something Amazing
                    </p>
                    <div className="form-groups row">
                      <div className="form-group col-6 sm-12">
                        <label className="same-label" htmlFor="first_name">
                          First Name
                        </label>
                        <input
                          type="text"
                          className="form-control same-input"
                          name="first_name"
                          id="first_name"
                          placeholder="Enter First Name"
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="first_name"
                          render={(error) => (
                            <span className="error">{error}</span>
                          )}
                        />
                      </div>
                      <div className="form-group col-6 sm-12">
                        <label className="same-label" htmlFor="last_name">
                          Last Name
                        </label>
                        <input
                          type="text"
                          className="form-control same-input"
                          name="last_name"
                          id="last_name"
                          placeholder="Enter Last Name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="last_name"
                          render={(error) => (
                            <span className="error">{error}</span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="same-label" htmlFor="email">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control same-input"
                        name="email"
                        id="email"
                        disabled="disabled"
                        placeholder="Enter email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="email"
                        render={(error) => (
                          <span className="error">{error}</span>
                        )}
                      />
                    </div>
                    <div className="form-group">
                      <label className="same-label" htmlFor="password">
                        Password
                      </label>
                      <div className="password-group">
                        <input
                          type={passwordVisibility ? "text" : "password"}
                          className="form-control same-input"
                          name="password"
                          id="password"
                          placeholder="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {!passwordVisibility && (
                          <img
                            src={visibilityOff}
                            onClick={changePasswordVisibility}
                            alt=""
                          />
                        )}
                        {passwordVisibility && (
                          <img
                            src={visibility}
                            onClick={changePasswordVisibility}
                            alt=""
                          />
                        )}
                      </div>
                      <ErrorMessage
                        name="password"
                        render={(error) => (
                          <span className="error">{error}</span>
                        )}
                      />
                    </div>
                    <div className="form-group">
                      <label className="same-label" htmlFor="confirm_password">
                        Confirm Password
                      </label>
                      <div className="password-group">
                        <input
                          type={confirmPasswordVisibility ? "text" : "password"}
                          className="form-control same-input"
                          name="confirm_password"
                          id="confirm_password"
                          placeholder="Confirm Password"
                          value={values.confirm_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {!confirmPasswordVisibility && (
                          <img
                            src={visibilityOff}
                            onClick={changeConfirmPasswordVisibility}
                            alt=""
                          />
                        )}
                        {confirmPasswordVisibility && (
                          <img
                            src={visibility}
                            onClick={changeConfirmPasswordVisibility}
                            alt=""
                          />
                        )}
                      </div>
                      <ErrorMessage
                        name="confirm_password"
                        render={(error) => (
                          <span className="error">{error}</span>
                        )}
                      />
                    </div>

                    <div className="form-group">
                      <label className="same-label" htmlFor="confirm_password">
                        Timezone *
                      </label>
                      <div className="password-group">
                        <FormField
                          placeholder="Select Timezone"
                          showPlaceholder={true}
                          type="select"
                          name="timezone_id"
                          errors={{}}
                          touched={{}}
                          value={values.timezone_id.toString()}
                          options={timezones}
                        />
                      </div>
                      <ErrorMessage
                        name="timezone_id"
                        render={(error) => (
                          <span className="error">{error}</span>
                        )}
                      />
                    </div>

                    <button type="submit" className="btn btn-app">
                      Sign Up
                    </button>
                    <p className="login-here">
                      Already Have An Account?{" "}
                      <Link to="/login">Login Here</Link>
                    </p>
                  </form>
                </div>
                <div className="signup-footer">
                  <ul className="languages d-none">
                    <li>English (UK)</li>
                    <li>हिन्दी</li>
                    <li>ਪੰਜਾਬੀ</li>
                    <li>বাংলা</li>
                    <li>Deutsch</li>
                    <li>ગુજરાતી</li>
                    <li>Español</li>
                  </ul>
                  <p>Copyright © 2021 MeetOcto.</p>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default connect(null, {
  getTimezones,
})(Signup);
