import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from "reactstrap";
import "antd/dist/antd.css";
import Loader from "../../components/Loader/Loader";
import IntegrationGoupImg from "../../assets/images/all-integ.png";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import LogoIcon from "../../assets/icons/meetocto_icon.svg";
import AdminLeftMenu from "../../templates/layouts/private/admin/left-menu";
import Zoom from "./components/Zoom";
import Zapier from "./components/Zapier";
import Stripe from "./components/Stripe";
import GoogleMeet from "./components/GoogleMeet";
import OutlookCalendar from "./components/OutlookCalendar";
import GoogleCalendar from "./components/GoogleCalendar";
import ExchangeCalendar from "./components/ExchangeCalendar";
import Office365Calendar from "./components/Office365Calendar";
import MicrosoftTeams from "./components/MicrosoftTeams";
 
const Integrations = (props) => {
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const [page, setPage] = useState("all");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const sidebarCollapse = () => setSideBar((prevState) => !prevState);

  return (
    <>
      <Container fluid>
        <Row>
        <Col xl="2" lg="3">
            <div className={`integ-sidebar d-lg-block d-none ${sideBar ? "active" : ''}`}>
              <div className="head">
                <div className="head-logo">
                  <img src={sideBar ? LogoIcon : Logo} alt="Logo" />
                  <button
                    type="button"
                    className="side-colappse"
                    onClick={sidebarCollapse}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        d="M7.70726 4.70711C8.09778 4.31658 8.09778 3.68342 7.70726 3.29289C7.31673 2.90237 6.68357 2.90237 6.29304 3.29289L1.58594 8L6.29304 12.7071C6.68357 13.0976 7.31673 13.0976 7.70726 12.7071C8.09778 12.3166 8.09778 11.6834 7.70726 11.2929L4.41436 8L7.70726 4.70711Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M13.7073 4.70711C14.0978 4.31658 14.0978 3.68342 13.7073 3.29289C13.3167 2.90237 12.6836 2.90237 12.293 3.29289L7.58594 8L12.293 12.7071C12.6836 13.0976 13.3167 13.0976 13.7073 12.7071C14.0978 12.3166 14.0978 11.6834 13.7073 11.2929L10.4144 8L13.7073 4.70711Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div className="body scrole cust-main-sidebar">
                <AdminLeftMenu></AdminLeftMenu>
              </div>
            </div>
          </Col>
          <Col xl="10" lg="9">
            <Row className="integ-header my-3">
              <Col className="col-md-6 col-3">
                <div className="integ-open-sidebar">
                  <button
                    type="button"
                    class="d-lg-none me-2 collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#chatListSidebar"
                    aria-expanded="false"
                    aria-controls="chatListSidebar"
                  >
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          d="M1 4C1 3.44772 1.44772 3 2 3H14C14.5523 3 15 3.44772 15 4C15 4.55228 14.5523 5 14 5H2C1.44772 5 1 4.55228 1 4Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M1 8C1 7.44772 1.44772 7 2 7H14C14.5523 7 15 7.44772 15 8C15 8.55228 14.5523 9 14 9H2C1.44772 9 1 8.55228 1 8Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M1 12C1 11.4477 1.44772 11 2 11H14C14.5523 11 15 11.4477 15 12C15 12.5523 14.5523 13 14 13H2C1.44772 13 1 12.5523 1 12Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </div>
              </Col>
              <Col className="col-md-6 col-9">
                <div className="invite-user">
                  <div class="buttonnotgcont">
                    <button class="invite-btn">
                      <a class="text-white align-item" href="#">
                        <svg
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.5 4C1.5 1.79086 3.29086 0 5.5 0C7.70914 0 9.5 1.79086 9.5 4C9.5 6.20914 7.70914 8 5.5 8C3.29086 8 1.5 6.20914 1.5 4ZM5.5 6C4.39543 6 3.5 5.10457 3.5 4C3.5 2.89543 4.39543 2 5.5 2C6.60457 2 7.5 2.89543 7.5 4C7.5 5.10457 6.60457 6 5.5 6Z"
                            fill="#fff"
                          ></path>
                          <path
                            d="M12.5 6C11.9477 6 11.5 6.44772 11.5 7V9H9.5C8.94771 9 8.5 9.44772 8.5 10C8.5 10.5523 8.94771 11 9.5 11H11.5V13C11.5 13.5523 11.9477 14 12.5 14C13.0523 14 13.5 13.5523 13.5 13V11H15.5C16.0523 11 16.5 10.5523 16.5 10C16.5 9.44772 16.0523 9 15.5 9H13.5V7C13.5 6.44772 13.0523 6 12.5 6Z"
                            fill="#fff"
                          ></path>
                          <path
                            d="M5.5 9C5.84247 9 6.17689 9.03443 6.5 9.10002V11.1707C6.18722 11.0602 5.85064 11 5.5 11C4.19378 11 3.08254 11.8348 2.67071 13H10.4C10.4656 13.3231 10.5 13.6575 10.5 14C10.5 14.5523 10.0523 15 9.5 15H1.5C0.947716 15 0.5 14.5523 0.5 14C0.5 11.2386 2.73858 9 5.5 9Z"
                            fill="#fff"
                          ></path>
                        </svg>
                        <span className="ms-3">Invite User</span>
                      </a>
                    </button>
                  </div>
                  <div className="custom-dropdown">
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                      <DropdownToggle caret>
                        <div className="imageaccountnav user-pic">
                          <img
                            src="https://www.staging.thrive-booking.com/backend/public//uploads/profile-images/profile-624-1700066969.jpg"
                            alt="user"
                          />
                        </div>
                        <span>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                          >
                            <path
                              d="M12.3535 8.06058L8.7071 11.707C8.31658 12.0976 7.68341 12.0976 7.29289 11.707L3.64644 8.06058C3.33146 7.7456 3.55454 7.20703 4 7.20703H12C12.4454 7.20703 12.6685 7.7456 12.3535 8.06058Z"
                              fill="#000"
                            ></path>
                          </svg>
                        </span>
                      </DropdownToggle>
                      <DropdownMenu>
                        <div className="scroller">
                          <DropdownItem>
                            <span className="menu-logo">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#0C4767" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" class="mr-50"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                            </span>
                            Profile
                          </DropdownItem>
                          <DropdownItem>
                            <span className="menu-logo">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#0C4767" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" class="mr-50"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                            </span>
                            Subscription
                          </DropdownItem>
                          <DropdownItem>
                            <span className="menu-logo">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#0C4767"
                                stroke-width="1.4"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="mr-50"
                              >
                                <circle cx="12" cy="12" r="3"></circle>
                                <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                              </svg>
                            </span>
                            Setting
                          </DropdownItem>
                          <DropdownItem>
                            <span className="menu-logo">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                viewBox="0 0 21.8 21.8"
                                class="mr-50"
                              >
                                <g transform="translate(-220.1 -32.1)">
                                  <g transform="translate(218 31)">
                                    <rect
                                      width="20"
                                      height="18"
                                      rx="2"
                                      transform="translate(3 4)"
                                      fill="none"
                                      stroke="#0C4767"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.4"
                                    ></rect>
                                    <line
                                      y2="3"
                                      transform="translate(18 2)"
                                      fill="none"
                                      stroke="#0C4767"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.4"
                                    ></line>
                                    <line
                                      y2="3"
                                      transform="translate(8 2)"
                                      fill="none"
                                      stroke="#0C4767"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.4"
                                    ></line>
                                    <line
                                      x2="20"
                                      transform="translate(3 9)"
                                      fill="none"
                                      stroke="#0C4767"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.4"
                                    ></line>
                                  </g>
                                  <line
                                    x2="5"
                                    transform="translate(224 43.5)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    x2="8.5"
                                    transform="translate(224 47.5)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                </g>
                              </svg>
                            </span>
                            Calendar Connections
                          </DropdownItem>
                          <DropdownItem>
                            <span className="menu-logo">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 24.563 24.563"
                                class="mr-50"
                              >
                                <g
                                  id="Group_9368"
                                  data-name="Group 9368"
                                  transform="translate(-64 -85)"
                                >
                                  <g
                                    id="Rectangle_3114"
                                    data-name="Rectangle 3114"
                                    transform="translate(67.281 88.281)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-width="1.4"
                                  >
                                    <rect
                                      width="18"
                                      height="18"
                                      rx="3"
                                      stroke="none"
                                    ></rect>
                                    <rect
                                      x="1"
                                      y="1"
                                      width="16"
                                      height="16"
                                      rx="2"
                                      fill="none"
                                    ></rect>
                                  </g>
                                  <line
                                    id="Line_5"
                                    data-name="Line 5"
                                    y2="2.724"
                                    transform="translate(72.333 86)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_13"
                                    data-name="Line 13"
                                    y2="2.7"
                                    transform="translate(65 102.358) rotate(-90)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_16"
                                    data-name="Line 16"
                                    y2="2.821"
                                    transform="translate(84.742 102.358) rotate(-90)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_8"
                                    data-name="Line 8"
                                    y2="2.478"
                                    transform="translate(72.333 106.085)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_6"
                                    data-name="Line 6"
                                    y2="2.724"
                                    transform="translate(76.845 86)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_12"
                                    data-name="Line 12"
                                    y2="2.7"
                                    transform="translate(65 97.845) rotate(-90)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_15"
                                    data-name="Line 15"
                                    y2="2.821"
                                    transform="translate(84.742 97.845) rotate(-90)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_9"
                                    data-name="Line 9"
                                    y2="2.478"
                                    transform="translate(76.845 106.085)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_7"
                                    data-name="Line 7"
                                    y2="2.724"
                                    transform="translate(81.358 86)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_11"
                                    data-name="Line 11"
                                    y2="2.7"
                                    transform="translate(65 93.333) rotate(-90)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_14"
                                    data-name="Line 14"
                                    y2="2.821"
                                    transform="translate(84.742 93.333) rotate(-90)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <line
                                    id="Line_10"
                                    data-name="Line 10"
                                    y2="2.478"
                                    transform="translate(81.358 106.085)"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-linecap="round"
                                    stroke-width="1.4"
                                  ></line>
                                  <g
                                    id="Group_9367"
                                    data-name="Group 9367"
                                    transform="translate(70.711 95.025)"
                                  >
                                    <path
                                      id="Path_24108"
                                      data-name="Path 24108"
                                      d="M81.371,57.179l-3.243,1.21,3.243,1.235"
                                      transform="translate(-78.128 -56.05)"
                                      fill="none"
                                      stroke="#0C4767"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.4"
                                    ></path>
                                    <path
                                      id="Path_24110"
                                      data-name="Path 24110"
                                      d="M78.128,57.179l3.243,1.21-3.243,1.235"
                                      transform="translate(-70.231 -56.05)"
                                      fill="none"
                                      stroke="#0C4767"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.4"
                                    ></path>
                                    <path
                                      id="Path_24109"
                                      data-name="Path 24109"
                                      d="M84.248,56l-2.265,4.778"
                                      transform="translate(-77.471 -56)"
                                      fill="none"
                                      stroke="#0C4767"
                                      stroke-linecap="round"
                                      stroke-width="1.4"
                                    ></path>
                                  </g>
                                </g>
                              </svg>
                            </span>
                            Embed Code
                          </DropdownItem>
                          <DropdownItem>
                            <span className="menu-logo">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="18"
                                viewBox="0 0 22.694 25.458"
                                class="mr-50"
                              >
                                <g
                                  id="Group_9380"
                                  data-name="Group 9380"
                                  transform="translate(-253.376 -196.913)"
                                >
                                  <path
                                    id="Path_24117"
                                    data-name="Path 24117"
                                    d="M263.054,220.788a3.294,3.294,0,0,1-3.194-3.073h.946a.331.331,0,0,0,.321-.34.359.359,0,0,0-.095-.243l-2.246-2.39a.307.307,0,0,0-.453,0h0l-2.246,2.39a.357.357,0,0,0,0,.483.31.31,0,0,0,.228.1h.976a3.956,3.956,0,0,0,3.836,3.756h1.925a.342.342,0,0,0,0-.683Z"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-width="1.4"
                                  ></path>
                                  <path
                                    id="Path_24118"
                                    data-name="Path 24118"
                                    d="M254.607,212.355a.332.332,0,0,1-.331-.331V210.7a4.974,4.974,0,0,1,9.947,0v1.327a.333.333,0,0,1-.331.331Z"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-miterlimit="10"
                                    stroke-width="1.4"
                                  ></path>
                                  <path
                                    id="Path_24119"
                                    data-name="Path 24119"
                                    d="M256.265,200.8a2.984,2.984,0,1,1,2.985,2.984A2.984,2.984,0,0,1,256.265,200.8Z"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-miterlimit="10"
                                    stroke-width="1.4"
                                  ></path>
                                  <path
                                    id="Path_24120"
                                    data-name="Path 24120"
                                    d="M265.554,221.471a.331.331,0,0,1-.331-.331v-1.327a4.974,4.974,0,0,1,9.947,0v1.327a.332.332,0,0,1-.331.331Z"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-width="1.4"
                                  ></path>
                                  <path
                                    id="Path_24121"
                                    data-name="Path 24121"
                                    d="M267.187,209.914a2.984,2.984,0,1,1,2.985,2.984A2.984,2.984,0,0,1,267.187,209.914Z"
                                    fill="none"
                                    stroke="#0C4767"
                                    stroke-width="1.4"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                            Admin Referral
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem>
                            <span className="menu-logo">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#0C4767"
                                stroke-width="1.4"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="mr-50"
                              >
                                <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                                <line x1="12" y1="2" x2="12" y2="12"></line>
                              </svg>
                            </span>
                            Logout
                          </DropdownItem>
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="all-integ">
              <Loader isShowLoader={isPageLoading} />
              <Col sm="12">
                <div className="integ-banner">
                  <Row className="justify-content-between align-items-center">
                    <Col md="8" lg="7">
                      <h1>
                        Connect <span className="text-brand">MeetOcto</span>{" "}
                        <br className="d-none d-lg-block" /> to your favorite
                        application.
                      </h1>
                    </Col>
                    <Col md="4" lg="5">
                      <img src={IntegrationGoupImg} alt="All Integration" />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col sm="12">
                <Row>
                  <Col md="4" xl="3">
                    <div className="int-sidebar">
                      <Link
                        to="#"
                        className={`${page === "all" ? "active" : ""}`}
                        onClick={() => setPage("all")}
                      >
                        All Integrations
                      </Link>
                      <Link
                        to="#"
                        className={`${page === "calendars" ? "active" : ""}`}
                        onClick={() => setPage("calendars")}
                      >
                        Calendars
                      </Link>
                      <Link
                        to="#"
                        className={`${page === "most-use" ? "active" : ""}`}
                        onClick={() => setPage("most-use")}
                      >
                        Most Use
                      </Link>
                      <Link
                        to="#"
                        className={`${
                          page === "video-conferencing" ? "active" : ""
                        }`}
                        onClick={() => setPage("video-conferencing")}
                      >
                        Video conferencing
                      </Link>
                      <Link
                        to="#"
                        className={`${
                          page === "extensions-app" ? "active" : ""
                        }`}
                        onClick={() => setPage("extensions-app")}
                      >
                        Extensions and apps
                      </Link>
                      <Link
                        to="#"
                        className={`${page === "email" ? "active" : ""}`}
                        onClick={() => setPage("email")}
                      >
                        Email messaging
                      </Link>
                      <Link
                        to="#"
                        className={`${page === "payments" ? "active" : ""}`}
                        onClick={() => setPage("payments")}
                      >
                        Payments
                      </Link>
                      <Link
                        to="#"
                        className={`${page === "api" ? "active" : ""}`}
                        onClick={() => setPage("api")}
                      >
                        API and connectors
                      </Link>
                    </div>
                  </Col>
                  <Col md="8" xl="9">
                    <div className="int-content">
                      <h2>All Integrations</h2>
                      <Row>
                        {page === "all" && (
                          <>
                            <GoogleCalendar />
                            <GoogleMeet />
                            <Office365Calendar />
                            <OutlookCalendar />
                            <ExchangeCalendar />
                            <Zapier />
                            <Zoom />
                            <MicrosoftTeams />
                            <Stripe />
                          </>
                        )}
                        {page === "calendars" && (
                          <>
                            <GoogleCalendar />
                            <Office365Calendar />
                            <OutlookCalendar />
                            <ExchangeCalendar />
                          </>
                        )}
                        
                        {page === "most-use" && (
                          <>
                            <GoogleCalendar />
                            <Zoom />
                            <GoogleMeet />
                          </>
                        )}
                        {page === "video-conferencing" && (
                          <>
                            <Zoom />
                            <MicrosoftTeams />
                            <GoogleMeet />
                          </>
                        )}

                        {page === "extensions-app" && (
                          <>
                            <OutlookCalendar />
                          </>
                        )}
                        {page === "email" && (
                          <>
                            <OutlookCalendar />
                          </>
                        )}
                        {page === "payments" && (
                          <>
                            <Stripe />
                          </>
                        )}
                        {page === "api" && (
                          <>
                            <Zapier />
                          </>
                        )}
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Integrations;
