import React from "react";
import logoSm from "../../../assets/images/logo-sm.png";
import likeImg from "../../../assets/images/like.png";
import { useNavigate } from "react-router-dom";
import setAuthToken from "../../../utils/setAuthToken";

const ThanksForPayment = () => {
  const navigate = useNavigate();
  return (
    <div className="main-container">
      <header>
        <div className="header-container">
          <div className="logo">
            <img src={logoSm} alt="" />
          </div>
        </div>
      </header>

      <div className="thanks page">
        <div className="page-desc">
          <img src={likeImg} />
          <h1>Thanks For Your Payment!</h1>
          <p>
            An email has been sent to your email to confirm your email address.
          </p>
          <button
            className="btn btn-app"
            style={{ marginTop: 30 }}
            onClick={() => {
                localStorage.removeItem("jwtToken");
                localStorage.removeItem("userType");
                localStorage.removeItem("businessId");
                localStorage.removeItem("userTimezone");
                localStorage.removeItem("userTimezoneId");
                localStorage.removeItem("config");
                localStorage.removeItem("tooltips");
                localStorage.removeItem("page");
                setAuthToken(false);
                navigate("/admin/login");
              }
            }
          >
            Let's Start Scheduling
          </button>
        </div>
      </div>

      <footer>
        <div className="footer-container">
          <ul className="languages d-none">
            <li>English (UK)</li>
            <li>हिन्दी</li>
            <li>ਪੰਜਾਬੀ</li>
            <li>বাংলা</li>
            <li>Deutsch</li>
            <li>ગુજરાતી</li>
            <li>Español</li>
          </ul>
          <p>Copyright © 2021 MeetOcto.</p>
        </div>
      </footer>
    </div>
  );
};

export default ThanksForPayment;
