import React, { useState } from "react";
import { Row, Col, Modal, ModalBody, Input, Button } from "reactstrap";
import { cancelEvent } from "../../../../actions/admin/scheduled-event-actions";
import { connect } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
import { notification, gotProperty } from "../../../../utils/utility";
import { sendPostRequest } from "../../../../actions/app-actions";

const UpcomingCancelModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState("");

  /**
   * on cancel event
   */
  const onCancel = () => {
    const {
      cancelEvent,
      handleClose,
      handleViewDetailClose,
      loadUpcomingEvents,
      isBulk,
      isStaffCancellation,
      sendPostRequest,
      data,
    } = props;
    setIsLoading(true);
    if (isBulk) {
      // CS - Cancel by Service provider

      sendPostRequest({
        url: `/booking/bulk-cancel`,
        data: {
          ids: data,
          booking_status: "CS",
          cancellation_reason: reason,
        },
        onSuccess: function (response) {
          notification.success("Events successfully canceled");
          handleClose();
          loadUpcomingEvents();
        },
        onError: function (error) {
          console.log("error", error);
        },
      });
    } else {
      cancelEvent({
        data: {
          booking_id: props.data.id,
          cancellation_reason: reason,
          booking_status: "CS",
          is_cancelled_by_staff : isStaffCancellation ? 'E' : 'D'  
        },
        onSuccess: function (response) {
          console.log(response);
          setIsLoading(false);
          notification.success("Event is successfully canceled");
          handleClose();
          handleViewDetailClose();
          loadUpcomingEvents();
        },
        onError: function (error) {
          console.error(error);
          setIsLoading(false);
        },
      });
    }
  };

  return (
    <>
      <Loader isShowLoader={isLoading} />
      <Modal
        isOpen={props.showCancelModal}
        toggle={props.handleClose}
        className="modal-dialog"
      >
        <ModalBody>
          <div className="">
            <div className="box_content">
              <div className="rd_inputselectheadermodalsetting">
                <button
                  className="rd_colsebtn"
                  onClick={props.handleClose}
                ></button>
              </div>

              <h5 className="rd_modalheadertext rd_modalheadertextnm">
                Cancel Event
              </h5>
              <Row>
                {!props.isBulk && (
                  <>
                    <Col lg="12" className="text-center">
                      <div className="vd-description pt-1 pb-3">
                        {props.data ? props.data.invitee : null}
                      </div>
                    </Col>
                    <Col lg="12" className="text-center">
                      <div className="vd-description pt-1 pb-3">
                        {props.data && gotProperty(props.data, "when")
                          ? `${props.data.when}   ${props.data.start_time}-${props.data.end_time}`
                          : null}
                      </div>
                    </Col>
                  </>
                )}

                <Col lg="12" className="text-center">
                  <div
                    className="vd-description pt-1 pb-3"
                    style={{ lineHeight: "1.2" }}
                  >
                    Please confirm that you would like to cancel this event. A
                    cancellation email will also go out to the invitee.
                  </div>
                </Col>

                <Col lg={{ size: 10, offset: 1 }} className="text-center">
                  <Input
                    type="textarea"
                    className="form-control"
                    name="reason"
                    placeholder="Reason"
                    onChange={({ target }) => {
                      const { value } = target || {};
                      setReason(value);
                    }}
                  />
                </Col>

                <Col lg={{ size: 5, offset: 1 }} className="mt-4">
                  <Button
                    className="btn btn-danger"
                    type="button"
                    onClick={props.handleClose}
                  >
                    No, don't cancel
                  </Button>
                </Col>

                <Col lg="5" className="mt-4">
                  <Button
                    type="submit"
                    className="btn btn-app"
                    onClick={onCancel}
                  >
                    Yes, cancel
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default connect(null, {
  cancelEvent,
  sendPostRequest,
})(UpcomingCancelModal);
