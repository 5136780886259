import axios from "../axios-instance";
import { GET_ERRORS, SET_CURRENT_USER, LOGOUT_SUCCESS } from "./types";
import setAuthToken from "../utils/setAuthToken";
import isEmpty from "../validation/is-empty";

export const loginUser = (userData) => (dispatch) => {
  // Send Request for tooltips and save it in localstorage
  axios.get("tooltips").then((res) => {
    const { data } = res.data;
    localStorage.setItem("tooltips", JSON.stringify(data));
  });

  axios
    .post("login", userData)
    .then((res) => {
      // Save to localStorage
      const { access_token, jwt_custom_claims } = res.data.data;

      localStorage.setItem("config", JSON.stringify(jwt_custom_claims));

      localStorage.setItem("userTimezone", jwt_custom_claims.timezone);
      localStorage.setItem("userTimezoneId", jwt_custom_claims.timezone_id);

      localStorage.setItem("page", jwt_custom_claims.page);

      // Set token to ls
      localStorage.setItem("jwtToken", access_token);
      // Set token to Auth header
      setAuthToken(access_token);
      dispatch(setCurrentUser(jwt_custom_claims.user));
    })
    .catch((err) => {
      console.log("error", err);
      let message = err.response.data.message;
      if (!isEmpty(err.response.data.data)) {
        message = err.response.data.data.email;
      }
      dispatch({
        type: GET_ERRORS,
        payload: message,
      });
    });
};

// Set logged in user
export const setCurrentUser = (user) => {
  return {
    type: SET_CURRENT_USER,
    payload: user,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("userType");
  localStorage.removeItem("businessId");
  localStorage.removeItem("userTimezone");
  localStorage.removeItem("userTimezoneId");
  localStorage.removeItem("config");
  localStorage.removeItem("tooltips");
  localStorage.removeItem("page");
  // Remove auth header for future requests
  setAuthToken(false);
  dispatch({
    type: GET_ERRORS,
    payload: null,
  });
  dispatch({
    type: LOGOUT_SUCCESS,
  });
  // Set current user to {} which will set isAuthenticated to false
  //dispatch(setCurrentUser({}));
};
