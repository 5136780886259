import React from "react";
import logoSm from "../../assets/images/logo-sm.png";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";

const SignupComplete = (props) => {
  return (
    <>
      <div>
        <header>
          <div className="header-container">
            <div className="logo">
              <img src={logoSm} alt="" />
            </div>
          </div>
        </header>
        <div className="text-center">
          <h1>Congratulations</h1>
        </div>
        <div className="container">
          <p className="r-p">
            You have successfully created your staff profile, As a bonus we have
            also created your own free profile so that you can better manage
            your personal and business meetings.
          </p>

          <p className="r-p">
            Click on Continue button to complete your own profile or skip and do
            it later to login to your staff account.
          </p>

          <Col md="12" className="text-right mt-2 mb-2">
            <Link to="/staff/dashboard" className="skip-btn">
              <button className="rd_mainbuttonstylelight" type="button">
                Do That Another Time
              </button>
            </Link>
            <Link to="/signup/profile-step-one" className="skip-btn">
              <button className="rd_mainbuttonstyleorange" type="button">
                Continue
              </button>
            </Link>
          </Col>
        </div>
      </div>
    </>
  );
};

export default SignupComplete;
