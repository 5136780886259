import { USER_TYPES } from "../routes/types/private";
import CryptoJS from "crypto-js";
import { USER_TYPE_PREFIX } from "../utils/prefixes";

/**
 * Get initial link for user based on user type after login
 * @param String userType
 * @returns String
 */
export const getUserDashboardLink = (userType) => {
  switch (userType) {
    case USER_TYPES.admin:
      return getUserLandingPage();

    case USER_TYPES.customer:
      return "/customer/dashboard";

    case USER_TYPES.staff:
      return "/staff/dashboard";

    case USER_TYPES.superAdmin:
      return "/super-admin/dashboard";

    default:
      return getUserLandingPage();
  }
};

/**
 * Get user landing page
 */
export const getUserLandingPage = () => {
  if (localStorage.page) {
    switch (localStorage.page) {
      case "stats":
        return "/admin/dashboard";
      case "scheduled-events":
        return "/admin/scheduled-events";
      default:
        return "/admin/dashboard";
    }
  }
  return "/admin/dashboard";
};

/**
 * Get List of user roles
 * @returns Mixed
 */
export const getUserRoles = () => {
  if (localStorage.config) {
    const config = JSON.parse(localStorage.config);
    return config.roles;
  }
  return null;
};

/**
 * Get list of admin businesses
 * @returns Mixed
 */
export const getUserBusinesses = () => {
  if (localStorage.config) {
    const config = JSON.parse(localStorage.config);
    return config.businesses;
  }
  return null;
};

/**
 * encrypt user role
 * @param String role
 */
export const encryptUserRole = (role) => {
  return CryptoJS.SHA256(USER_TYPE_PREFIX + role);
};

/**
 * Get staff Business id
 * @returns mixed
 */
export const getStaffBusinessId = () => {
  if (localStorage.config) {
    const config = JSON.parse(localStorage.config);
    return config.staff_business_id;
  }
  return null;
};

/**
 * get business id
 * @return mixed
 */
export const getAdminBusinessId = () => {
  if (localStorage.businessId) {
    return localStorage.businessId;
  }
  return null;
};

/**
 * get logged in user id
 * @return mixed
 */
export const getLoggedInUserId = () => {
  if (localStorage.config) {
    const config = JSON.parse(localStorage.config);
    return config?.user?.id;
  }
  return null;
};
